import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import { UiCard } from '@uireact/card';
import { UiText } from '@uireact/text';
import * as packageJson from '../package.json';
import Playground from '../../../src/Playground';
import { UiFlexGrid, UiFlexGridItem } from '../src/';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <h1 {...{
      "id": "uiflexgrid"
    }}>{`UiFlexGrid`}</h1>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/packages/flex/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`Component for creating flex grids`}</p>
    </blockquote>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation @uireact/flex`}</p>
    </blockquote>
    <h2 {...{
      "id": "uiflexgrid-1"
    }}>{`UiFlexGrid`}</h2>
    <Playground __position={1} __code={'<UiFlexGrid alignItems=\"center\" gap=\"five\">\n  <UiFlexGridItem>\n    <UiCard>\n      <UiText>Item 1</UiText>\n    </UiCard>\n  </UiFlexGridItem>\n  <UiFlexGridItem grow={1}>\n    <UiCard>\n      <UiText>Item 2</UiText>\n    </UiCard>\n  </UiFlexGridItem>\n</UiFlexGrid>'} __scope={{
      props,
      DefaultLayout,
      Props,
      UiCard,
      UiText,
      packageJson,
      Playground,
      UiFlexGrid,
      UiFlexGridItem,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiFlexGrid alignItems="center" gap="five" mdxType="UiFlexGrid">
    <UiFlexGridItem mdxType="UiFlexGridItem">
      <UiCard mdxType="UiCard">
        <UiText mdxType="UiText">Item 1</UiText>
      </UiCard>
    </UiFlexGridItem>
    <UiFlexGridItem grow={1} mdxType="UiFlexGridItem">
      <UiCard mdxType="UiCard">
        <UiText mdxType="UiText">Item 2</UiText>
      </UiCard>
    </UiFlexGridItem>
  </UiFlexGrid>
    </Playground>
    <h3 {...{
      "id": "uiflexgrid-props"
    }}>{`UiFlexGrid Props`}</h3>
    <Props of={UiFlexGrid} mdxType="Props" />
    <h3 {...{
      "id": "uiflexgriditem-props"
    }}>{`UiFlexGridItem Props`}</h3>
    <Props of={UiFlexGridItem} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      